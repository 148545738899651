<template>
  <div class="w-full h-full overflow-hidden">
    <split-pane
        split="vertical"
        :default-percent="20"
        class="w-full h-full">
        <div class="w-full h-full flex flex-col overflow-y-auto" slot="paneL">
          <v-list>
            <v-subheader>Filter by Status</v-subheader>
            <template v-for="filter in statusFilters">
              <v-list-item dense :key="filter.key" link @click="filterTodos('status', filter.key)">
                <v-list-item-title>
                  {{ filter.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
          <v-list>
            <v-subheader>Filter by Date</v-subheader>
            <template v-for="filter in dueDateFilters">
              <v-list-item dense :key="filter.key" link @click="filterTodos('dueAt', filter.key)">
                <v-list-item-title>
                  {{ filter.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </div>
        <div class="w-full h-full flex flex-col overflow-hidden" slot="paneR">
          <div class="flex flex-row space-x-2 items-center">
            <p class="my-0 mx-2 pa-0 text-center">Labels:</p>
            <label-list
              :labels="selectedLabels"
              @click-close="removeSelectedLabel"
              >
            </label-list>
            <label-selector-menu
              :selected-labels="selectedLabels">
            </label-selector-menu>
          </div>
          <div
            class="flex flex-col grow px-0 mx-0 overflow-y-auto"
            style="height: calc(100% - 40px);">
            <template v-if="todoRecords.length == 0">
              <div class="flex flex-col shrink space-y-3">
                <div class="text-lg w-full text-center">No todos yet. Click following button to create one.</div>
                <div class="flex flex-row items-center place-content-center">
                  <v-btn color="primary">
                    <v-icon>mdi-plus</v-icon>
                    <span>Create new todo</span>
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-if="groupView">
                <template v-for="(group, index) in todoGroups">
                  <div
                    :key="index"
                    class="flex flex-col px-0 mx-0">
                    <v-subheader>{{ group.name }}</v-subheader>
                    <template v-for="todo in group.todos">
                      <div
                        :key="todo.id"
                        class="flex flex-row hover:bg-gray-100 items-center px-4 space-x-3 h-12"
                        @click="() => {}">
                        <v-checkbox></v-checkbox>
                        <div class="grow ma-0">{{ todo.title }}</div>
                        <div class="space-x-1 mx-4">
                          <label-list
                            :labels="todo.labels"
                            >
                          </label-list>
                        </div>
                        <p class="ma-0">{{ todo.dueDate }}</p>
                      </div>
                    </template>
                  </div>
                </template>
              </template>
              <template v-else>
                <template v-for="todo in todoRecords">
                  <div
                    :key="todo.id"
                    class="flex flex-row hover:bg-gray-100 shrink items-center px-4 space-x-3 h-12"
                    @click="() => {}">
                    <v-checkbox></v-checkbox>
                    <div class="grow ma-0">{{ todo.title }}</div>
                    
                    <div class="space-x-1 mx-4">
                      <label-list
                        :labels="todo.labels"
                        >
                      </label-list>
                    </div>
                    <p class="my-0 mx-2 px-3 text-sm py-1 bg-gray-200 rounded-lg">
                      Due at:
                      {{ `${ todo.dueAt.getFullYear()}/${ todo.dueAt.getMonth() }/${ todo.dueAt.getDate()}` }}
                    </p>
                    <div style="width: 120px">
                      <v-chip small
                        text-color="white"
                        :color="
                          todo.status == 'progress' ? 'blue' : (todo.status == 'completed' ? 'green' :  'red')
                        ">
                        {{ todo.status }}
                      </v-chip>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </div>
        </div>
    </split-pane>
  </div>
</template>

<script>
import _ from 'lodash'
import SplitPane from '@/components/splitpane'
import MakeLoadMoreMixin from '@/api/make-loadmore-mixin'
import LabelList from '@/components/LabelList.vue'
import LabelSelectorMenu from '@/components/LabelSelectorMenu'

export default {
  name: 'TodosView',

  components: {
    SplitPane,
    LabelSelectorMenu,
    LabelList
  },
  
  mixins: [
    MakeLoadMoreMixin({ name: 'Todo' }),
  ],

  data() {
    return {
      groupView: false,
      statusFilters: [
        { key: 'overdue', title: 'Over Due', icon: '' },
        { key: 'progress', title: 'In Progress', icon: '' },
        { key: 'done', title: 'Done', icon: '' }
      ],
      selectedStatus: null,
      dueDateFilters: [
        { key: '1', title: 'Due Today', icon: '' },
        { key: '2', title: 'Due Tomorrow', icon: '' },
        { key: '3', title: 'Due in Less than 3 days', icon: '' },
        { key: '7', title: 'Due in Less than a week', icon: '' },
        { key: '30', title: 'Due in Less than a month', icon: '' },
      ],
      selectedDueDate: null,
      selectedLabels: []
    }
  },

  computed: {
    todoFilters() {
      let filters = []
      if (this.selectedLabels.length > 0) {
        filters.push({
          op: 'containedIn',
          key: 'labels',
          value: this.selectedLabels
        })
      }

      if (this.selectedStatus) {
        let statusList = this.selectedStatus
        if (!_.isArray(this.selectedStatus)) {
          statusList = [this.selectedStatus]
        }
        if (statusList.length == 1) {
          filters.push({
            op: 'equalTo',
            key: 'status',
            value: statusList[0]
          })
        } else if (statusList.length > 1) {
          filters.push({
            op: 'containedIn',
            key: 'status',
            value: statusList
          })
        }
      }

      if (this.selectedDueDate) {
        // should we use 
        let days = _.toInteger(this.selectedDueDate)
        let date = new Date()
        date.setDate(date.getDate() + days)
        filters.push({
          op: 'lessThan',
          key: 'dueAt',
          value: date
        })
      }

      return filters
    },

    todoGroups() {
      let grouppedTodos = _.groupBy(this.todoRecords, 'node')
      console.log(grouppedTodos)
      return []
    }
  },

  methods: {
    filterTodos(filterType, value) {
      if (filterType === 'status') {
        this.selectedStatus = value
      } else {
        this.selectedDueDate = value
      }
    },

    removeSelectedLabel(label) {
      let index = _.findIndex(this.selectedLabels, (x) => x.id == label.id)
      if (index > -1) {
        this.selectedLabels.splice(index, 1)
      }
    }
  },

  mounted() {
    if (!this.$api.isAuthenticated()) {
      this.$router.push({ name: 'login', query: { ref: 'user-todos' } })
    }
  },
}
</script>